import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import auth from '../Auth';

class TopMenu extends Component {
  state = {
    domainName: window.location.hostname,
  };

  handleLogout = () => {
    auth.logout(() => {
      this.props.handlenavigationroutes('/');
    });
  };

  render() {
    return (
      <Navbar bg="dark" expand="lg" variant="dark">
        <Navbar.Brand href="/dashboard">
          {this.state.domainName === 'admin.palletiq.com' || this.state.domainName === 'admin-dev.palletiq.com'
            ? 'PalletIQ'
            : this.state.domainName === 'admin.myemptyshelves.com' || this.state.domainName === 'admin.emptyshelves.com'
            ? 'EmptyShelves'
            : this.state.domainName === 'admin.bookteriors.com'
            ? 'BOOKTERIORS'
            : this.state.domainName === 'admin.brandonsbooks.com'
            ? 'BRANDONS BOOKS'
            : this.state.domainName === 'admin.blueinkbooks.com'
            ? 'BLUEINKBOOKS'
            : 'MBB'}{' '}
          Admin
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={this.props.activeKey}>
            <NavDropdown title="Manage" id="nav-dd-manage" bg="dark">
              <Nav.Link onClick={() => this.props.handlenavigationroutes('/triggers')}>Triggers</Nav.Link>
              <Nav.Link onClick={() => this.props.handlenavigationroutes('/users')}>Users</Nav.Link>
              <Nav.Link onClick={() => this.props.handlenavigationroutes('/profiles')}>Profiles</Nav.Link>
              <Nav.Link onClick={() => this.props.handlenavigationroutes('/sounds')}>Sounds</Nav.Link>
            </NavDropdown>

            <Nav.Link onClick={() => this.props.handlenavigationroutes('/scans')}>Scans</Nav.Link>
            <Nav.Link onClick={() => this.props.handlenavigationroutes('/shipping-plans')}>Shipping Plans</Nav.Link>
            <Nav.Link onClick={() => this.props.handlenavigationroutes('/shipments')}>Shipments</Nav.Link>

            {'isReevaluate' in this.props && this.props.isReevaluate === true && (
              <Nav.Link onClick={() => this.props.handlenavigationroutes('/reevaluate-queue')}>
                Reevaluate-Queue
              </Nav.Link>
            )}

            {/* {this.props.adminData.premium_level === 1 && (
              <NavDropdown title="Premium" id="nav-dd-premium" eventKey="box-tool-2">
                <Nav.Link eventKey="box-tool" onClick={() => this.props.handlenavigationroutes("/box-tool")}>
                  Box Content
                </Nav.Link>
              </NavDropdown>
            )} */}

            {'access_level' in this.props.adminData && this.props.adminData.access_level === 0 && (
              <NavDropdown title="Super Admin" id="nav-dd-god-mode" key="super-admin">
                <React.Fragment>
                  <Nav.Link onClick={() => this.props.handlenavigationroutes('/invite')}>Invite Users</Nav.Link>
                  <Nav.Link onClick={() => this.props.handlenavigationroutes('/mp-batches')}>MP Sources</Nav.Link>
                  {/* <Nav.Link onClick={() => this.props.handlenavigationroutes("/mp-sources")}>MP Sources (old)</Nav.Link> */}
                  <Nav.Link onClick={() => this.props.handlenavigationroutes('/mp-inbound')}>MP Inbound</Nav.Link>
                  <Nav.Link onClick={() => this.props.handlenavigationroutes('/ziffit-ledger')}>Ziffit Ledger</Nav.Link>
                  <Nav.Link onClick={() => this.props.handlenavigationroutes('/whitelabel')}>WhiteLabel Sites</Nav.Link>
                </React.Fragment>
              </NavDropdown>
            )}
          </Nav>

          <Nav activeKey={this.props.activeKey} className="justify-content-end">
            {'sources' in this.props && this.props.sources !== undefined && this.props.sources.length >= 1 && (
              <NavDropdown title="Sources" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => this.props.fetchStats('')}>ALL</NavDropdown.Item>
                <NavDropdown.Divider />
                {this.props.sources.map((source) => {
                  return (
                    <NavDropdown.Item onClick={() => this.props.fetchStats(source.source)} href="">
                      {source.source}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            )}
            <Nav.Item>
              <Nav.Link
                target="_blank"
                href="https://docs.google.com/document/d/e/2PACX-1vQkk0GmYnf62LO28VZyFYsGx4nrwQqiBPFe3PE57dBLbgMUueQLbja_hpzv0gK7DQ/pub?embedded=true"
              >
                User Manual
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => this.props.handlenavigationroutes('/account-settings')}>Account</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopMenu;
